import AnnouncementList from "./AnnouncementList";
import AnnouncementsTrigger from "./AnnouncementsTrigger";
import { createRoot } from "react-dom/client";

function hideAnnouncement(id) {
    const hiddenAnnouncements = JSON.parse(localStorage.getItem("hiddenAnnouncements") || '[]')
    hiddenAnnouncements.push(id)
    localStorage.setItem('hiddenAnnouncements', JSON.stringify(hiddenAnnouncements))
    renderAnnouncementList()
}

export default function renderAnnouncementList() {
    const hiddenAnnouncements = JSON.parse(localStorage.getItem("hiddenAnnouncements") || '[]')
    const currentAnnouncements = window.announcements.filter(a => {
        // Force local time
        const showAt = Date.parse(a.showAt + 'T00:00:00')
        const hideAt = Date.parse(a.hideAt + 'T23:59:59')
        if(new Date() < showAt) {
            return false;
        }
        if(hideAt < new Date()) {
            return false;
        }
        return true
    })
    const announcementRoot = createRoot(document.getElementById('announcementContainer'));
    announcementRoot.render(<AnnouncementList announcements={currentAnnouncements} hiddenAnnouncements={hiddenAnnouncements} hideAnnouncement={hideAnnouncement} />);
    const dismissedAnnouncementRoot = createRoot(document.getElementById('dismissedAnnouncementContainer'));
    dismissedAnnouncementRoot.render(<AnnouncementsTrigger announcements={currentAnnouncements} />);
}