import { t } from 'ftc-common/i18n';

export default function Announcement ({announcement, hideClose, hideAnnouncement}) {
    const classMapping = {
        "INFO": "info",
        "ERROR": "danger",
        "WARNING": "warning"
    }[announcement.type]
    const iconMapping = {
        "INFO": "info-circle",
        "ERROR": "exclamation-circle",
        "WARNING": "exclamation-circle"
    }[announcement.type]
    return <div className={`card border-${classMapping} mb-3`} style={{margin: '0.5rem'}}>
        <div className="card-body" style={{padding: '0.75rem'}}>
            { hideClose ? null : <button className="btn float-right close-icon" data-effect="fadeOut" onClick={ () => hideAnnouncement(announcement.announcementId) }><i className="fa fa-times" title={t`close announcement`}></i></button> }
            <h5 className="card-title">
              <i className={`fa fa-${iconMapping} text-${classMapping}`}></i><span style={{paddingLeft: '0.5em'}}>{ announcement.title }</span>
            </h5>
            <div dangerouslySetInnerHTML={{ '__html': announcement.body }}></div>
        </div>
      </div>;
}