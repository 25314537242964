import Announcement from "./Announcement";

export default function AnnouncementList({announcements, hiddenAnnouncements, hideAnnouncement}) {
    if(announcements.length === 0) return null;
    const notShownAnnouncements = announcements.filter(a => hiddenAnnouncements.includes(a.announcementId))
    return <>
        <div>
        {announcements.filter(a => !hiddenAnnouncements.includes(a.announcementId))
            .map((announcement) => <Announcement key={announcement.announcementId} announcement={announcement} hideAnnouncement={hideAnnouncement}/>)}
        </div>
        <div className="collapse" id="announcementCollapse">
            {notShownAnnouncements.map((announcement) => <Announcement key={announcement.announcementId} announcement={announcement} hideClose />)}
        </div>
    </>;
}